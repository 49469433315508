<template>
  <div class="terms">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <div class="row">
      <div class="col-md-6">
        <div class="p-5">
          <div class="mb-5">
            <a @click="goBack" href="#"><i class="fa fa-arrow-left"></i> </a>  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="font-size:1.4em;color:#000;"> Checkout </span>
          </div>

          <div class="mx-auto" style="width: 70%">
            <div class="row">
              <div class="col-md-12">

                <div class="mb-4">
                  <h4>Order Summary</h4>
                </div>

                <table class="table ">
                  <tr style="padding-top: 10px; ">
                    <td>Make: </td>
                    <td>{{ quotation.make | capitalize}} </td>
                  </tr>
                  <tr>
                    <td>Model: </td>
                    <td>{{ quotation.model | capitalize }} </td>
                  </tr>
                  <tr>
                    <td>Value of Vehicle: </td>
                    <td>Ksh. {{ quotation.sumInsured | currency('', 0)  }} </td>
                  </tr>
                  <tr>
                    <td>Registration Number: </td>
                    <td> {{ quotation.vehicleRegistrationNumber }} </td>
                  </tr>
                  <tr>
                    <td>Product: </td>
                    <td>SWITCH </td>
                  </tr>
                </table>

                <div class="mt-5">
                  <h5 class="text-secondary">Total Due</h5>
                  <h4>KES. {{ quotation.distancePremium | currency('', 0)  }}</h4>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="col-md-6" >
        <div class="personal-info p-5" >

          <form method="post" action="#" v-on:submit.prevent="validateCheckoutForm">

            <div class="mx-auto" style="width: 70%">
              <div class="mb-4">
                <h5>Personal Information</h5>
              </div>

              <div class="form-group mb-3">
                <label :class="{ 'text-danger': $v.checkout.customerFirstName.$error }">First Name: </label> <br/>
                <input v-model.trim="$v.checkout.customerFirstName.$model" class="form-control" type="text" placeholder="Enter first  name" />
                <div class="text-danger" v-if="!$v.checkout.customerFirstName.required && $v.checkout.customerFirstName.$dirty">First name is required</div>
              </div>

              <div class="form-group mb-3">
                <label >Middle Name: </label> <br/>
                <input v-model="checkout.customerMiddleName" class="form-control" type="text" placeholder="Enter middle name" />

              </div>

              <div class="form-group mb-3">
                <label :class="{ 'text-danger': $v.checkout.customerLastName.$error }">Last Name: </label> <br/>
                <input v-model.trim="$v.checkout.customerLastName.$model" class="form-control" type="text" placeholder="Enter last name" />
                <div class="text-danger" v-if="!$v.checkout.customerLastName.required && $v.checkout.customerLastName.$dirty">Last name is required</div>
              </div>

              <div class="form-group mb-3">
                <label>Mobile Phone </label> <br/>
                <input class="form-control" type="text" placeholder="+254" v-model="checkout.customerPhoneNumber" />
              </div>
              <div class="form-group mb-3">
                <label :class="{ 'text-danger': $v.checkout.customerEmail.$error }">Email Address </label> <br/>
                <input v-model.trim="$v.checkout.customerEmail.$model"  class="form-control" type="text"  />
                <div class="text-danger" v-if="!$v.checkout.customerEmail.required && $v.checkout.customerEmail.$dirty">Email address is required</div>
              </div>

              <div class="mb-4 mt-5">
                <h5>Payment Details</h5>
              </div>

              <ul class="nav nav-tabs nav-fill" id="myTab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" @click="updatePaymentMethod('Mpesa')" id="home-tab-fill" data-toggle="tab" href="#mpesa" role="tab" aria-controls="home-fill" aria-selected="false">M-pesa</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link " @click="updatePaymentMethod('card')" id="profile-tab-fill" data-toggle="tab" href="#card" role="tab" aria-controls="profile-fill" aria-selected="true">Card</a>
                </li>
              </ul>

              <!-- Tab panes -->
              <div class="tab-content pt-1">
                <div class="tab-pane active" id="mpesa" role="tabpanel" aria-labelledby="home-tab-fill">
                  <div class="col-md-12 mt-3" >
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group" v-if="this.paymentMethod == 'Mpesa'">
                          <label :class="{ 'text-danger': $v.checkout.MSISDN.$error }" ><strong>M-pesa phone number:</strong></label> <br/>
                          <input class="form-control" v-model.trim="$v.checkout.MSISDN.$model"  type="text" placeholder="+2547XXXXXXXX" />
                          <div class="text-danger" v-if="!$v.checkout.MSISDN.required && $v.checkout.MSISDN.$dirty">Phone number is required</div>
                        </div>
                      </div>
                      <div>
                        <small id="emailHelp" class="form-text text-muted"> Please ensure you have your phone near you. You will receive a prompt on the number above. Enter your M-pesa PIN to authorise payment. </small>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="tab-pane " id="card" role="tabpanel" aria-labelledby="profile-tab-fill">
                  <div class="p-5">
                    <div class="row">
                      <div class="col-md-12">
                        <label><strong>Card Number:</strong></label> <br/>
                        <input class="form-control" type="text" v-mask="'9999 9999 9999 9999'" v-model="card.number" />
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-md-8">
                        <label><strong>Expiration Date:</strong></label> <br/>
                        <input class="form-control" type="text" v-mask="'99/99'" v-model="card.cardExpiry" placeholder="mm/yy" />
                      </div>
                      <div class="col-md-4">
                        <label><strong>CVV:</strong></label> <br/>
                        <input class="form-control" type="text" v-mask="'999'" v-model="card.cvv" />
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-md-12">
                        <label><strong>Card Holder Name:</strong></label> <br/>
                        <input class="form-control" type="text" v-model="card.nameOnCard" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group" >
                  <label><strong>Referral Code (Optional ):</strong></label> <br/>
                  <input class="form-control" v-model="checkout.referredBy.code"  type="text"  />
                </div>
              </div>


              <div class="mt-5 mb-5">
                <button type="submit"  class=" btn  btn-lg  quote-btn quotation-button">  <strong>Pay KES. </strong> {{  quotation.distancePremium | currency('', 0) }} </button>
              </div>
            </div>

          </form>


        </div>


      </div>
    </div>

    <modal name="success-checkout" :resizable="resizable"
           :adaptive="adaptive"
           :draggable="draggable">
      <div class="p-5 text-center success-checkout">
        <img src="@/client/assets/images/success_check.png">


        <h4 class="text-success">Payment successful</h4>

        <div>
          <button type="button" class="btn  btn-secondary btn-block quote-btn" > Proceed To dashboard </button>
        </div>

      </div>

    </modal>

    <modal name="stk-push-wait" :resizable="resizable"
           :adaptive="adaptive"
           :draggable="draggable">
      <div class="p-5 text-center ">

        <p>
          Kindly check your phone for an STK push <br/>
          to <strong>enter your Mpesa pin</strong> to complete <br/>
          the payment.
        </p>

        <div class="mt-3">
          <img src="@/client/assets/images/progress.png">
        </div>

        <!--<div class="mt-3 mb-3">
          <RouterLink :to="'/personal-details/'+checkout.quoteId" class="btn  btn-secondary btn-block quote-btn" > OK </RouterLink>
        </div>-->

      </div>
    </modal>


    <modal name="ds-modal" :resizable="resizable"
           :adaptive="adaptive"
           :draggable="draggable">

      <div style="background-color:#16263D;" class="p-2">
        <h5 class="text-white">Card OTP</h5>
      </div>

      <div class="p-5 text-center success-checkout">


        <iframe :src="redirectUrl" style="width: 100%; height: 500px;" />

      </div>

    </modal>

  </div>
</template>

<script>

  import { required} from 'vuelidate/lib/validators'
  import { mapActions } from "vuex";
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    name: 'home',
    data () {
      return {
        resizable: true,
        adaptive: true,
        draggable: true,
        isLoading: false,
        fullPage: true,
        quotation:{},
        checkout:{
          requestAmount: "",
          currencyCode: "KES",
          accountNumber: "speedflakes@gmail.com",
          serviceCode: "AICARE",
          dueDate: new Date().toJSON().slice(0,10).replace(/-/g,'/'),
          requestDescription: "tripbuddy",
          MSISDN: "",
          customerFirstName: "",
          customerEmail: "",
          paymentOrigin: "switch",
          quoteId:"",
          customerMiddleName:"",
          customerLastName:"",
          customerPhoneNumber:"",
          referredBy: {
            code: ""
          }
        },
        card:{
          nameOnCard:"",
          number:"",
          cvv:"",
          cardExpiry:"",
          expiry:{
            month:"",
            year:""
          }
        },
        redirectUrl:"",
        paymentMethod:"Mpesa",
        transactionId:"",
        intervalId:""
      };
    },
    mounted(){
      this.quotation = JSON.parse(localStorage.getItem("quote"));

      this.checkout.quoteId = this.quotation.quoteId;
      this.checkout.requestAmount = this.quotation.distancePremium;

    },

    components: { Loading },

    validations(){
        if(this.paymentMethod == 'Mpesa'){
          return {
            checkout:{
              MSISDN:{required},
              customerFirstName:{required},
              customerEmail:{required},
              customerLastName:{required}
            },
          }
        }
        else{
          return {
            checkout:{
              customerFirstName:{required},
              customerEmail:{required},
              customerLastName:{required}
            },
          }
        }
    },

    methods:{
      ...mapActions(["makeMobileMoneyPayment","updatePersonalQuoteInformation","makeCardPayment","getTransactionsByMerchantId"]),
      updatePaymentMethod(paymentMethod){
         this.paymentMethod = paymentMethod;
      },
      makeMpesaPayment(){

        let self = this;

        this.isLoading = true;

        this.makeMobileMoneyPayment(this.checkout).then((response) => {
          self.isLoading = false;
          self.$modal.show('stk-push-wait');

          //console.log(response);

          self.transactionId = response.merchantTransactionID;

          self.startTransactionCheck();

        }).catch(error => {
          self.isLoading = false;
          console.log(error);
        })
      },

      submitCardPayment(){

        let expiry = this.card.cardExpiry.split("/");

        this.card.expiry.month = expiry[0];
        this.card.expiry.year = expiry[1];
        this.card.number = this.card.number.replaceAll(' ', '');

        //delete this.card.cardExpiry;

        let cardCheckoutData = {
          customerFirstName: this.checkout.customerFirstName,
          customerLastName: this.checkout.customerLastName,
          currencyCode: "KES",
          countryCode: "KE",
          customerEmail: this.checkout.customerEmail,
          requestDescription: "Switch product payment",
          order: {
            accountNumber: this.checkout.customerEmail,
            chargeAmount: this.checkout.requestAmount+"",
            currencyCode: "KES",
            requestDescription: "Order XX Purchase"
          },
          card: this.card,
          billingDetails: {
            address: {
              city: "Nairobi",
              countryCode: "KE"
            },
            customer: {
              firstName: this.checkout.customerFirstName,
              emailAddress: this.checkout.customerEmail,
              surname:  this.checkout.customerLastName,
              mobileNumber: this.checkout.customerPhoneNumber
            }
          },
          paymentOrigin: "switch",
          packageName: "switch-per-km",
          quoteId: this.checkout.quoteId
        };



        let self = this;

        this.isLoading = true;

        this.makeCardPayment(cardCheckoutData).then((paymentResults) => {
          self.isLoading = false;

          if(paymentResults.message == "Success"){

            self.$toast.open({
              message: "Payment successful!",
              type: 'success',
              duration:5000,
              position:"top-right"
            });

            self.$router.push("/personal-details/"+self.checkout.quoteId);

          }
          else{
            self.$toast.open({
              message: paymentResults.message,
              type: 'warning',
              duration:5000,
              position:"top-right"
            });
          }


        }).catch(error => {
          self.isLoading = false;

          self.$toast.open({
            message: error.message,
            type: 'danger',
            duration:5000,
            position:"top-right"
          });

          console.log(error);
        })


      },

      startTransactionCheck(){
        let self = this;


        this.intervalId =  setInterval(function (){self.getTransactionsByMerchantId({transactionId: self.transactionId}).then((response) => {

          clearInterval(self.intervalId);


          self.isLoading = false;

          self.$toast.open({
            message: "Payment Successful!",
            type: 'success',
            duration:5000,
            position:"top-right"
          });


          self.$router.push("/personal-details/"+self.checkout.quoteId);

          //personal-details
          //self.$route.push({ name: 'personal-details', params: { quoteId: self.quoteId } })


        }).catch(error => {

          //console.log(error);
          console.log("No payment received");
        })}, 5000);
      },

      updateUserInformation(){

        let self = this;
        this.isLoading = true;

        let quoteData = {
          fullName:this.checkout.customerFirstName +" "+this.checkout.customerLastName,
          phoneNumber:this.checkout.MSISDN,
          emailAddress:this.checkout.customerEmail,
          config:"switch",
          referredBy: {
            code: this.checkout.referredBy.code
          }
        };


        this.updatePersonalQuoteInformation({quoteId: this.checkout.quoteId, quoteData: quoteData}).then((response) => {
          self.isLoading = false;

          if(self.paymentMethod == 'Mpesa'){
            self.makeMpesaPayment();
          }
          else{
            self.submitCardPayment();
          }



        }).catch(error => {
          self.isLoading = false;
          console.log(error);
        })
      },

      validateCheckoutForm(){
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.updateUserInformation()
        }
      },
      goBack(){
        this.$router.push('/quote');
      },

    }
  }
</script>

<style>

  table td{
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .personal-info{
    min-height: 80vh;
    background-color: #F2F2F2;
  }

  .project-tab {
    padding: 10%;
    margin-top: -8%;
  }
  #tabs{
    background: #007b5e;
    color: #eee;
  }
  #tabs h6.section-title{
    color: #eee;
  }
  #tabs .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #000;
    background-color: transparent;
    border-color: transparent transparent #666666;
    border-bottom: 3px solid !important;
    font-size: 16px;
    font-weight: bold;
  }
  .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    color: #666666;
    font-size: 16px;
    font-weight: 600;
  }

  .nav-link:hover {
    border: none;
  }

  .personal-info button{
    width: 100%;
  }

  .success-checkout img{
    width: 80px;
    height: 60px;
  }

  .success-checkout h4 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .success-checkout button{
    width: 80%;
  }
</style>
